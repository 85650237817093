/* eslint-disable no-mixed-operators */
import { useState, useEffect, useRef } from 'react';
import playButton from '../../play-button.svg';
import stopButton from '../../stop-button.svg';

import ProgressBar from '../ProgressBar/ProgressBar';

export default function AudioPlayer({disabled, srcFile, initialPlayTimeSec, secondsPlayed, onSolutionEnter, onPlayEnd, onFinalPlayEnd, autoPlay }) {

    const audioContextRef = useRef(null);
    const track = useRef(null);

    const [audioPlayer, setAudioPlayer] = useState({
        playing: false,
        input: ''
    });

    useEffect(() => {
        
        // already loaded
        if (track.current != null) {
            return;
        }

        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

        // get the audio element
        const audioElement = document.querySelector("audio");

        // pass it into the audio context
        track.current = audioContextRef.current.createMediaElementSource(audioElement);
        track.current.connect(audioContextRef.current.destination);
        track.current.mediaElement.currentTime = initialPlayTimeSec; // set initial point
      }, [initialPlayTimeSec, srcFile]);

    useEffect(() => {
        
        // already loaded
        if (track.current === null) {
            return;
        }

        if (autoPlay) {
            track.current.mediaElement.currentTime = 0;
            setStartPlaying();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [autoPlay]);

    function playNextSlot() {
        if (!disabled) {
            setStartPlaying();
            setTimeout(() => { setEndPlaying(); }, 1000);
        }
    }

    function setStartPlaying() {
        // Check if context is in suspended state (autoplay policy)
        if (audioContextRef.current.state === "suspended") {
            audioContextRef.current.resume();
        }

        track.current.mediaElement.play();
        setAudioPlayer({...audioPlayer, playing: true });
    }
    
    function setEndPlaying() {
        if (track.current !== null) {
            track.current.mediaElement.pause();
        }

        setAudioPlayer({...audioPlayer, playing: false });
        onPlayEnd();
    }

    function updateInput(inputData) {
        setAudioPlayer({...audioPlayer, input: inputData });
    }

    function handleSubmit() {
        if (!disabled) {
            onSolutionEnter(audioPlayer.input);
        }
    }

    function stopPlayingForever() {
        if (track.current !== null) {
            track.current.mediaElement.pause();
        }

        setAudioPlayer({...audioPlayer, playing: false });
        onFinalPlayEnd();
    }

    return (
        <div className="audioPlayer">
            <div>
                <button onClick={audioPlayer.playing? () => stopPlayingForever() : () => playNextSlot() } className="play"><img src={audioPlayer.playing? stopButton : playButton} alt="play" title="play" /></button>
                <ProgressBar isPlaying={audioPlayer.playing && !autoPlay} secondsPlayed={secondsPlayed} />
            </div>
            <audio src={process.env.PUBLIC_URL + `/songs/${srcFile}`}></audio>
            <div className="songInput">
                <input 
                    id="songGuess" 
                    name="songGuess" 
                    type="text" 
                    onChange={(ev) => updateInput(ev.target.value)} 
                    value={audioPlayer.input }
                    placeholder="Das ist doch...">
                </input>
                <button onClick={handleSubmit} disabled={ disabled || audioPlayer.input == null || audioPlayer.input.length <= 0 }>Absenden</button>
            </div>
        </div>
    );
}