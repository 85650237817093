import { useState, useEffect } from 'react';

import AudioPlayer from '../AudioPlayer/AudioPlayer';
import SuccessMessage from '../SuccessMessage/SuccessMessage';

export default function Game({ songName, songFilename, solutionLetterPosition, initialPlayTimeSec  }) {

    const initialGameStats = {
                              falseGuesses: [],
                              secondsPlayed: 0,
                              secondsFound: null
                            };

    const [gameStats, setGameStats] = useState(() => {
      const localData = localStorage.getItem(songFilename);
      return localData !== null
        ? JSON.parse(localData)
        : initialGameStats;
    });

    useEffect(() => {
        localStorage.setItem(songFilename, JSON.stringify(gameStats));  
        if (gameStats.showErrorMessage === true) {
            setTimeout(() => {setGameStats({...gameStats, showErrorMessage: false })}, 3000);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameStats]);

    function validateInput(inputData) {
      if (inputData && inputData.localeCompare(songName, undefined, { sensitivity: 'base' }) === 0) {
        showWellDone();
      } else {
        showErrorMessage(inputData);
      }
  }

  function showWellDone() {
    setGameStats({...gameStats, secondsFound: gameStats.secondsPlayed, autoPlay: true });
  }

  function showErrorMessage(inputtedSong) {
    const falseGuessesNext = [...gameStats.falseGuesses];
    falseGuessesNext.push(inputtedSong);
    setGameStats({...gameStats, falseGuesses: falseGuessesNext, showErrorMessage: true });
  }

  function updateSecondsPlayed() {
    setGameStats({ ...gameStats, secondsPlayed: gameStats.secondsPlayed + 1 });
  }

  function endPlaying() {
    setGameStats({ ...gameStats, autoPlay: false });
  }

  function handleCloseSuccessMessage() {
    setGameStats({ ...gameStats, secondsFound: null });
  }

    return (<div>{ gameStats.showErrorMessage && <div className="error"><span>{gameStats.falseGuesses.slice(-1)}</span>? Cooler Song, aber nicht derjenige, den wir suchen.</div> }
    { gameStats.secondsFound != null && <SuccessMessage 
    secondsFound={gameStats.secondsFound} 
    songName={songName} 
    solutionLetterPosition={solutionLetterPosition} 
    falseGuesses={gameStats.falseGuesses}
    closePopup={handleCloseSuccessMessage} /> }
    <h3>Kannst Du den heutigen Full Lemon Song erraten?</h3>
    <AudioPlayer 
      disabled={ gameStats.secondsFound != null } 
      autoPlay={ gameStats.autoPlay }
      srcFile={songFilename} 
      initialPlayTimeSec={initialPlayTimeSec} 
      secondsPlayed={gameStats.secondsPlayed} 
      onSolutionEnter={validateInput}
      onPlayEnd={updateSecondsPlayed}
      onFinalPlayEnd={endPlaying} />
      </div>);
}