export default function SuccessMessage({ secondsFound, songName, solutionLetterPosition, falseGuesses, closePopup }) {

    function getCongratsText(){
        switch (secondsFound) {
          case 0:
            return 'Magie? Du hast Dir den Song ja gar nicht angehört!';
          case 1:
            return `Genie! Du hast den Song "${getSongNameWithRedLetter()}" in nur 1 Sekunde erkannt! Du bist das ultimative Full Lemon Mitglied.`;
          case 2:
            return `Beeindruckend! Du hast nur 2 Sekunden benötigt, um den Song "${getSongNameWithRedLetter()}" zu erkennen. Full Lemon ist stolz auf Dich.`;
          case 3:
            return `Super! 3 Sekunden sind schnell vorbei, Du hast den Song "${getSongNameWithRedLetter()}" herausgehört!`;
          case 4: 
            return `Gratuliere! Nach 4 Sekunden hast Du den Song "${getSongNameWithRedLetter()}" erkannt.`;
          case 5:
            return `Gut! 5 Sekunden hat es gedauert, doch Du hast den Song "${getSongNameWithRedLetter()}" herausgefunden.`;
          case 6:
            return `Nicht schlecht! Du hast zwar 6 Sekunden gebraucht, aber der Song "${getSongNameWithRedLetter()}" ist Dir doch noch erschienen.`;
          case 7:
            return `Naja! Bei der nächsten Bandprobe vielleicht mal auf die Tafel schauen, wie unsere Songs heissen. Du hast 7 Sekunden benötigt, bist Du "${getSongNameWithRedLetter()}" erkannt hast.`;
          case 8:
            return `Grade noch! Du hast Dich noch aus der Affäre gezogen und den Song "${getSongNameWithRedLetter()}" in 8 Sekunden herausgefunden. Ein wenig Üben zuhause würde sicher nicht schaden.`;
          default: 
            return `"${getSongNameWithRedLetter()}" ist richtig. Hmmm... Vielleicht sollten wir diesen Song etwas öfters spielen...`;
        }
      }
    
      function getSongNameWithRedLetter() {
        return Array.from(songName).map((val, currentIndex) => {
          return currentIndex === solutionLetterPosition ? '<span class="solutionLetter">' + val + '</span>' : val;
        }).join('');
      }

      function getGuessesText() {
        switch (falseGuesses.length) {
            case 0:
                return '';
            default:
                const uniqueGuesses = [...new Set(falseGuesses)];
                const falseAnswers = [];
                falseAnswers.push(<div key="g+0">...</div>);
                falseAnswers.push(uniqueGuesses.map((v, i) => { return (<div key={`g-${i}`}>{v}</div>) }));
                falseAnswers.push(<div key="g+1">So heisst der Song aber schon nicht &#x1F648; </div>);
                return falseAnswers;
        }
      }

    return (<div className="success" >
    { secondsFound <= 0 && <span className="close" onClick={ closePopup }>X</span> }
    <div className="congrats" dangerouslySetInnerHTML={{ __html: getCongratsText() }} /><br />
    { secondsFound > 0 && <div>Trage Dir das rote Zeichen der heutigen Lösung auf Dein Lösungsblatt ein.</div> }
    <br/><div className="falseGuesses">{ getGuessesText() }</div></div>);

}