import { useState, useEffect } from 'react';

import logo from './logo_inverse.png';
import './App.css';
import Game from './components/Game/Game';
import { useParams } from "react-router-dom";

function App() {

  const { dayId } = useParams();
  const [dailySongData, setDailySongData] = useState();

  useEffect(() => {

    // eslint-disable-next-line no-undef
    fetch(process.env.PUBLIC_URL + `/songs/${dayId}.json`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setDailySongData(data);
      })
      .catch(err => console.log(err));
  }, [dayId]);

  return (
    <div className="App">
      <header>
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Advents-Lemonordle</h1>
        </div>
        <h2>{dayId}. Dezember</h2>
      </header>
      <main>
        <p className="intro">Errate den Full Lemon Song anhand des gegebenen Ausschnitts. Je weniger Sekunden Du dazu benötigst, desto besser!<br/>
        So geht's: Clicke auf den Play-Knopf, um jeweils eine Sekunde eines Full Lemon Songs zu hören. Wenn Du denkst, Du kennst den Song, gib die Lösung ein und prüfe, ob Du richtig liegst. 
        Wenn Du etwas mehr vom Song hören willst, clicke wieder auf Play und die nächste Sekunde wird abgespielt.
        </p>
        {dailySongData && <Game 
          songName={dailySongData.songName} 
          songFilename={dailySongData.fileName} 
          solutionLetterPosition={dailySongData.solutionLetterPosition} 
          initialPlayTimeSec={dailySongData.initialPlayTimeSec} /> }
      </main>
    </div>
  );
}

export default App;
