export default function ProgressBar({ isPlaying, secondsPlayed }) {

    const secondsBar = [];

    for(var i = 1; i <= secondsPlayed; i++) {
        secondsBar.push(<span className="fillProgress" key={`prog-${i}`}>{i} sec</span>);
    }

    if (isPlaying) {
        var nextIndex = secondsPlayed + 1;
        secondsBar.push(<span className="fillProgress active" key={`prog-${nextIndex}`}></span>)
    }

    return (
        <div className="progress">{secondsBar}</div>
    );
}